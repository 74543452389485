import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_mik7N71GmK from "/opt/build/repo/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import templates_plugin_7e4f6da8_cLvyS16cBv from "/opt/build/repo/.nuxt/templates.plugin.7e4f6da8.ejs";
import unocss_MzCDxu9LMj from "/opt/build/repo/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_B4ptqVdIfe from "/opt/build/repo/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import gtag_6Z9lu4VVCL from "/opt/build/repo/src/plugins/gtag.ts";
import scrollToTop_qK8NwO4Kxq from "/opt/build/repo/src/plugins/scrollToTop.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_client_mik7N71GmK,
  templates_plugin_7e4f6da8_cLvyS16cBv,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  ssr_plugin_B4ptqVdIfe,
  gtag_6Z9lu4VVCL,
  scrollToTop_qK8NwO4Kxq
]