
import { defuFn } from '/opt/build/repo/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "firebaseConfig": {
    "apiKey": "AIzaSyBtujnDYP9W0LlEJItaafoWws0DDezNsTI",
    "authDomain": "portfolio-3d0ff.firebaseapp.com",
    "projectId": "portfolio-3d0ff",
    "storageBucket": "portfolio-3d0ff.appspot.com",
    "messagingSenderId": "898677260759",
    "appId": "1:898677260759:web:3bc8b4a5882e2e82810b92",
    "measurementId": "G-36QL5QJRS5"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "auth": false,
    "config": {
      "apiKey": "AIzaSyBtujnDYP9W0LlEJItaafoWws0DDezNsTI",
      "authDomain": "portfolio-3d0ff.firebaseapp.com",
      "projectId": "portfolio-3d0ff",
      "storageBucket": "portfolio-3d0ff.appspot.com",
      "messagingSenderId": "898677260759",
      "appId": "1:898677260759:web:3bc8b4a5882e2e82810b92",
      "measurementId": "G-36QL5QJRS5"
    }
  }
}



export default /* #__PURE__ */ defuFn(inlineConfig)
