import { default as indexMUmX8mAfjNMeta } from "/opt/build/repo/src/pages/index.vue?macro=true";
import { default as robots_46txtryttcenKfYMeta } from "/opt/build/repo/src/pages/robots.txt.vue?macro=true";
export default [
  {
    name: indexMUmX8mAfjNMeta?.name ?? "index",
    path: indexMUmX8mAfjNMeta?.path ?? "/",
    meta: indexMUmX8mAfjNMeta || {},
    alias: indexMUmX8mAfjNMeta?.alias || [],
    redirect: indexMUmX8mAfjNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: robots_46txtryttcenKfYMeta?.name ?? "robots.txt",
    path: robots_46txtryttcenKfYMeta?.path ?? "/robots.txt",
    meta: robots_46txtryttcenKfYMeta || {},
    alias: robots_46txtryttcenKfYMeta?.alias || [],
    redirect: robots_46txtryttcenKfYMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/robots.txt.vue").then(m => m.default || m)
  }
]